import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>Beyza</h1>
        <div className="subtitle">Dance Enthusiast & World Explorer</div>
      </header>
      
      <main className="App-main">
        <section className="about-section">
          <h2>About Me</h2>
          <p>
            Hey there! 👋 I'm Beyza, born and raised in the vibrant city of Amsterdam.
            My heart beats to the rhythm of bachata, and my soul finds peace in exploring 
            hidden gems around the city. With Dutch roots and a global mindset, I love 
            bringing people together through dance and shared experiences.
          </p>
        </section>

        <section className="passions-section">
          <h2>What Makes Me, Me</h2>
          <div className="passions-grid">
            <div className="passion-item">
              <h3>💃 Dancing</h3>
              <p>Bachata is my ultimate passion! There's something magical about how music and 
                movement can tell stories and connect people from all walks of life.</p>
            </div>
            <div className="passion-item">
              <h3>🌍 Traveling</h3>
              <p>Exploring new cultures, trying local cuisines, and making friends around the 
                globe. Every journey is a new chapter in my story.</p>
            </div>
            <div className="passion-item">
              <h3>☕ Coffee Culture</h3>
              <p>Amsterdam's cozy cafes are my second home. I love discovering artisanal coffee 
                spots and meeting interesting people over a perfect cup of cappuccino.</p>
            </div>
            <div className="passion-item">
              <h3>🎨 Art & Culture</h3>
              <p>From Van Gogh to street art, I'm fascinated by how art shapes our city's 
                identity and brings color to everyday life.</p>
            </div>
          </div>
        </section>

        <section className="amsterdam-section">
          <h2>My Amsterdam Story</h2>
          <p>
            Growing up in Amsterdam has shaped who I am today. From cycling along the canals 
            to dancing in historic buildings, every corner of this city holds a special memory. 
            I love showing visitors the authentic side of Amsterdam - beyond the tourist spots, 
            where real magic happens in local communities and hidden courtyards.
          </p>
        </section>

        <section className="contact-section">
          <h2>Let's Connect</h2>
          <p>Whether you want to share a dance, explore the city's best coffee spots, or 
            just chat about life's adventures, I'd love to connect!</p>
          <div className="social-links">
            <a href="https://instagram.com/beyza" target="_blank" rel="noopener noreferrer">Instagram</a>
            <a href="mailto:beyza@example.com">Email</a>
          </div>
        </section>
      </main>
    </div>
  );
}

export default App;
